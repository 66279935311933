import cleanDeep from 'clean-deep';
import { snakeCase } from 'lodash';

import { deeplyApplyKeyTransform } from '@/helpers/transformData';

import AuthenticatedResource from './authenticatedResource';

export default class OrderResource extends AuthenticatedResource {
  id = undefined;

  created = '';

  budget = '';

  description = '';

  title = '';

  updated = '';

  viewed = '';

  expectedDate = '';

  endDate = '';

  files = '';

  status = '';

  tags = [];

  candidates = {};

  pk() {
    return this.id?.toString();
  }

  static detailShape() {
    const superShape = super.detailShape();
    super.hideErrors = true;

    return {
      ...superShape,
      fetch: async (params, body) => {
        const response = await superShape.fetch(params, body);

        if (!response.order) {
          return Promise.reject(response);
        }

        return response;
      },
      schema: { order: super.asSchema() }
    };
  }

  static listShape() {
    const superShape = super.listShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        const response = await superShape.fetch(deeplyApplyKeyTransform(cleanDeep(params), snakeCase), body);

        return response;
      },
      schema: { orders: [super.asSchema()], prePage: null, total: null }
    };
  }

  static createShape() {
    const superShape = super.createShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        const response = await superShape.fetch(
          cleanDeep(params),
          cleanDeep(body, { emptyArrays: false, nullValues: false })
        );

        return response;
      },
      schema: { order: super.asSchema() }
    };
  }

  static updateShape() {
    const superShape = super.updateShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        const response = await superShape.fetch(
          cleanDeep(params),
          cleanDeep(body, { emptyArrays: false, nullValues: false })
        );

        return response;
      },
      schema: { order: super.asSchema() }
    };
  }

  static urlRoot = `${CONFIG.apiBaseUrl}/order`;
}

OrderResource.STATUS = {
  ARCHIVED: 'archived',
  CONFIRMATION: 'confirmation',
  DRAFT: 'draft',
  IN_PROGRESS: 'in-progress',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished'
};
