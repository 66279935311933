import { message } from 'antd';
import { camelCase, snakeCase } from 'lodash';
import Router from 'next/router';
import { Resource } from 'rest-hooks';

import AuthToken from '@/helpers/authToken';
import { deeplyApplyKeyTransform } from '@/helpers/transformData';
import userContext, { getUserFromToken } from '@/helpers/userContext';

const b64EncodeUnicode = (str) =>
  btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(`0x${p1}`)));

export default class AuthenticatedResource extends Resource {
  static fetchOptionsPlugin = (options) => {
    const authToken = AuthToken.getToken();

    return {
      ...options,
      headers: {
        ...options.headers,
        ...(authToken ? { Authorization: authToken } : {})
      }
    };
  };

  static async fetch(method = 'get', url, body) {
    if (body && !(body instanceof File)) {
      // eslint-disable-next-line no-param-reassign
      body = deeplyApplyKeyTransform(body, snakeCase);
    }

    const response = await this.fetchResponse(method, url, body);
    let jsonResponse = response.status === 200 ? {} : { status: response.status };

    try {
      jsonResponse = {
        ...jsonResponse,
        ...(await response.json())
      };
    } catch (error) {
      jsonResponse = {
        ...jsonResponse,
        error
      };
    }

    jsonResponse = deeplyApplyKeyTransform(jsonResponse, camelCase);

    if (jsonResponse?.authToken !== undefined && url !== `${CONFIG.apiBaseUrl}/auth`) {
      if (jsonResponse.authToken) {
        AuthToken.updateToken(jsonResponse.authToken);
        this.updateUser();
      } else {
        AuthToken.removeToken();
        this.updateUser();
        Router.replace('/');
      }
    }

    return jsonResponse;
  }

  static fetchResponse(method, url, body) {
    let options = {
      method: method.toUpperCase(),
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    };

    if (this.fetchOptionsPlugin) {
      options = this.fetchOptionsPlugin(options);
    }

    if (body) {
      if (body instanceof File) {
        options.method = 'POST';
        options.headers['X-File-Name'] = b64EncodeUnicode(body.name);
        options.headers['X-File-Type'] = body.id;
        options.headers['Content-Type'] = body.type;
        options.body = body;
      } else {
        options.body = JSON.stringify(body);
      }
    }

    return fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          response
            .clone()
            .json()
            .then((errorBody) => {
              if (this.hideErrors) {
                return;
              }

              message.error(errorBody?.error || 'Произошла ошибка. Повторите попытку');
            });

          if (response.status === 401 && AuthToken.getToken()) {
            AuthToken.removeToken();
            this.updateUser();
            Router.replace('/');
          }
        }

        return response;
      })
      .catch((error) => {
        // ensure CORS, network down, and parse errors are still caught by NetworkErrorBoundary
        if (error instanceof TypeError) {
          error.status = 400;
        }

        console.warn(error);
        throw error;
      });
  }

  static updateUser = () => {
    userContext.setUser(getUserFromToken());
  };
}
