import cleanDeep from 'clean-deep';
import { snakeCase } from 'lodash';

import { MASK_CHAR } from '@/helpers/constants';
import { deeplyApplyKeyTransform } from '@/helpers/transformData';

import AuthenticatedResource from './authenticatedResource';

export default class UserResource extends AuthenticatedResource {
  id = undefined;

  email = '';

  role = '';

  name = '';

  contactName = '';

  created = '';

  lastWas = '';

  phone = '';

  socials = {};

  description = '';

  avatar = {};

  ordersCnt = {};

  ratings = {};

  inn = '';

  taxSystem = '';

  website = '';

  addresses = [];

  pk() {
    return this.id?.toString();
  }

  static detailShape() {
    const superShape = super.detailShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        const response = await superShape.fetch(params, body);

        if (!response.user) {
          return Promise.reject(response);
        }

        if (response.user.email.includes(MASK_CHAR)) {
          response.user.socials = {};

          if (response.user.description) {
            let restoredDescription = response.user.description;
            restoredDescription = restoredDescription.replace(`<${MASK_CHAR}>`, '<p>');
            restoredDescription = restoredDescription.replace(`</${MASK_CHAR}>`, '</p>');
            response.user.description = restoredDescription;
          }
        }

        return response;
      },
      schema: { user: super.asSchema() }
    };
  }

  static listShape() {
    const superShape = super.listShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        const response = await superShape.fetch(deeplyApplyKeyTransform(cleanDeep(params), snakeCase), body);

        if (!response.users) {
          return Promise.reject(response);
        }

        return response;
      },
      schema: { users: [super.asSchema()], prePage: null, total: null }
    };
  }

  static createShape() {
    const superShape = super.createShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        const response = await superShape.fetch(params, body);

        if (!response.user) {
          return Promise.reject(response);
        }

        return response;
      },
      schema: { user: super.asSchema() }
    };
  }

  static updateShape() {
    const superShape = super.updateShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        const response = await superShape.fetch(
          cleanDeep(params),
          cleanDeep(body, { emptyArrays: false, nullValues: false })
        );

        if (!response.user) {
          return Promise.reject(response);
        }

        return response;
      },
      schema: { user: super.asSchema() }
    };
  }

  static urlRoot = `${CONFIG.apiBaseUrl}/user`;
}

UserResource.ROLE = {
  ADMIN: 'admin',
  COMPANY: 'company',
  INDIVIDUAL: 'individual',
  PERSON: 'person'
};

UserResource.TAX_SYSTEM = {
  OSN: 'osn',
  USN: 'usn'
};

UserResource.ADDRESS_TYPE = {
  ACTUAL: 'actual',
  LEGAL: 'legal'
};
